import { render, staticRenderFns } from "./SeriesResults.vue?vue&type=template&id=134d7506&scoped=true&"
import script from "./SeriesResults.vue?vue&type=script&lang=ts&"
export * from "./SeriesResults.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "134d7506",
  null
  
)

export default component.exports